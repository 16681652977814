#job-description>.inside ._style__floating-box {
    margin-top: -80px
}

#job-description {
    margin-bottom: 130px;
    margin-top: 140px
}

#job-description > .inside {
    background: url("/files/theme/dist/images/Backgrounds/white-pattern-stellenanzeige.jpg");
    margin: -100px auto;
    padding: 50px;
  }

#job-description .text * {
    color: #000;
    font-size: 14px
}

.profession p {
    background-color: grey;
    margin-left: -50px;
    margin-right: -50px;
    text-align: center;
    padding: 20px
}

.profession p strong {
    color: #fff!important;
    font-size: 20px!important
}