/////////////////////////////////////////////////////////////////////////////
// SASS INDEX
//
// Imports all SCSS Files
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
////VENDOR COMPONENTS
/////////////////////////////////////////////////////////////////////////////

///
/// Import grid-system lib
///
/// @link https://github.com/ericam/susy
/// @link http://susy.readthedocs.org/en/latest/
///
@import "../../node_modules/susy/sass/_susy.scss";

///
/// Import media-query lib
///
/// @link https://github.com/at-import/breakpoint
/// @link http://breakpoint-sass.com/
///
@import '../../node_modules/breakpoint-sass/stylesheets/_breakpoint';


/////////////////////////////////////////////////////////////////////////////
//// BASE
/////////////////////////////////////////////////////////////////////////////

//
// System-wide variables
//
@import 'base/variables';

//
// System Functions and Mixins
//
@import 'base/system';

//
// Helper classes (e.g. clearfix)
//
@import 'base/helpers';

//
// Mixin Collection
//
@import 'base/mixins';

//
// Font Importer
//
@import 'base/fonts';

//
// Animation Library
//
@import 'base/animations';

/////////////////////////////////////////////////////////////////////////////
//// GLOBALS
/////////////////////////////////////////////////////////////////////////////

//
// Project independent, globally valid definitions
//

// Default normalize
@import 'globals/normalize';

// HTML, Framework and CMS Defaults
@import 'globals/defaults';

/////////////////////////////////////////////////////////////////////////////
//// COMPONENTS
/////////////////////////////////////////////////////////////////////////////

//
// Framework Components
//
//@import 'components/slider';
@import 'components/masonry';

/////////////////////////////////////////////////////////////////////////////
//// SPECIFICS
/////////////////////////////////////////////////////////////////////////////

//
// Layout definitions
//
@import 'specifics/layout';

//
// Typography
//
@import 'specifics/typography';

//
// Navigation Components and it's variants
//
@import 'specifics/navigation';

//
// Contao Modules, e.g. mod_article, mod_header, etc.
//
@import 'specifics/modules';

//
// Contao Elements, e.g. ce_text, ce_headline, etc.
//
@import 'specifics/elements';

//
// Custom components, e.g. page sections or elements flagged with a CSS Class
//
@import 'specifics/pages';

//
// Mixins and extends used through the theme
//
@import 'specifics/includes';

//
// Custom css for job offering
//
@import 'specifics/job-offering';


/////////////////////////////////////////////////////////////////////////////
//// WEBFONTS
/////////////////////////////////////////////////////////////////////////////

/* gudea-regular - latin */
@font-face {
  font-family: 'Gudea';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/gudea-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Gudea'),
  url('../fonts/gudea-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gudea-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/gudea-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/gudea-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/gudea-v10-latin-regular.svg#Gudea') format('svg'); /* Legacy iOS */
}
/* gudea-700 - latin */
@font-face {
  font-family: 'Gudea';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/gudea-v10-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Gudea Bold'), local('Gudea-Bold'),
  url('../fonts/gudea-v10-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/gudea-v10-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/gudea-v10-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/gudea-v10-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/gudea-v10-latin-700.svg#Gudea') format('svg'); /* Legacy iOS */
}

/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-slab-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-slab-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-slab-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-slab-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-slab-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-slab-v12-latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-slab-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-slab-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-slab-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-slab-v12-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-slab-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-slab-v12-latin-700.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}