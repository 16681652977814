/////////////////////////////////////////////////////////////////////////////
// MASONRY COMPONENTS
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
//// MASONRY TILES
/////////////////////////////////////////////////////////////////////////////

// param $width     width of the tile
// param $height    height of the tile
// param $gutter    gutter width
// param $stopExpr  nth-child expression to remove margin

@mixin masonry-tiles( $gutter : 5px, $animation : 'default', $duration : 0.7s )
{
    transition-duration:$duration;

    .masonry-brick
    {
        float:left;
        margin-right:$gutter;
        margin-bottom:$gutter;
        transition-duration:$duration;

        &.last
        {
            margin-right:0;
        }

        @include breakpoint( 'small-only' )
        {
            float:none;
            margin:0;
        }
    }

    @if( $animation == 'scale' )
    {

    }

    @else
    {
        transition-property:height, width;
        .masonry-brick { transition-property:left, right, top; }
    }
}