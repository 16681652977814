/////////////////////////////////////////////////////////////////////////////
// LAYOUT
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

//
// Susy Defaults
//
$susy: (
    output: isolate,
    container: $container__max,
    columns: 4,
    gutters: 18px/219px,
    global-box-sizing: border-box,
);

/////////////////////////////////////////////////////////////////////////////
//// SYSTEM BAR
/////////////////////////////////////////////////////////////////////////////

.system-bar {
    padding:0.5rem 15px;
    background-color:#e3e3e3;

    .inside {
        position:relative;
        display:flex;
        max-width:1200px;
        margin:0 auto;
    }
}

/////////////////////////////////////////////////////////////////////////////
//// CONTAINER LAYOUT
/////////////////////////////////////////////////////////////////////////////

//
// Site Container
//
.site-container
{
    position:relative;
    max-width:1200px;
    margin:0 auto;

    // Off-Canvas transform
    transition:transform;
    transition-duration: .4s;
}

//
// Header Container
//
.header-container
{
    position:relative;
    width:100%;

    img
    {
        width:100%;
    }
}


//
// Main Navigation Container
//
.main-nav-container
{
    .inside
    {
        @include container;
    }
}


//
// Logo Container
//
.logo-container
{
    .name
    {
        @include _use( visually-hidden );
    }
}


//
// Wraps the page Content
//
.content-container
{
    position:relative;

    .mod_article
    {
        @include rem( margin-top, spacer(2) );
        @include rem( margin-bottom, spacer(2) );
        @include rem( padding-top, spacer(2) );
        @include rem( padding-bottom, spacer(2) );
    }
}


//
// Wraps the Main Section
//
.main-container
{
    // default margin between elements
    .block + .block
    {
        @include rem( margin-top, spacer(1) );
    }
}


//
// Wraps the Sidebar
//
.sidebar-container
{
    // default margin between elements
    .block + .block
    {
        @include rem( margin-top, spacer(1) );
    }
}

//
// Footer Container
//
.footer-container
{
    @include rem( margin-top, spacer(1) );

    .inside
    {
        @include container;
    }
}

/////////////////////////////////////////////////////////////////////////////
//// TEMPLATES
/////////////////////////////////////////////////////////////////////////////

.template__default
{
    .main-container
    {
        width:$container__main;
        float:left;

        .mod_article > .inside
        {
            padding-left:165px;
            padding-right:28px;
        }
    }

    .sidebar-container
    {
        width:$container__aside;
        float:left;

        .mod_article > .inside
        {
            padding-right:100px;
            padding-left:28px;
        }
    }

    @include breakpoint( 'small-only' )
    {
        .main-container, .sidebar-container
        {
            width:100%;
            float:none;

            .mod_article
            {
                // override helper.layout.equalizeHeights()
                margin:auto;
                height:auto !important;

                & > .inside
                {
                    padding-left:15px;
                    padding-right:15px;
                }
            }
        }
    }
}

.template__full-width
{
    @include breakpoint( 'small-only' )
    {
        .main-container .mod_article > .inside
        {
            padding-left:15px;
            padding-right:15px;
        }
    }

    @include breakpoint( 'container-width' )
    {
        .main-container .mod_article > .inside
        {
            padding-left:15px;
            padding-right:15px;
        }
    }
}
