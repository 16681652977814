/////////////////////////////////////////////////////////////////////////////
// INCLUDES
//
// Mixins and extends used through the theme
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////
// EXTENDS
/////////////////////////////////////////

//
// Default border style
//
%border
{
    border:7px solid $color__greyscale--light;
}

//
// Default drop shadow style
//
%drop-shadow
{
    -webkit-box-shadow:0 12px 66px 0 rgba(0,0,0,0.75);
       -moz-box-shadow:0 12px 66px 0 rgba(0,0,0,0.75);
            box-shadow:0 12px 66px 0 rgba(0,0,0,0.75);

    @include breakpoint( 'small-only' )
    {
        -webkit-box-shadow:0 4px 20px 0 rgba(0,0,0,0.75);
           -moz-box-shadow:0 4px 20px 0 rgba(0,0,0,0.75);
                box-shadow:0 4px 20px 0 rgba(0,0,0,0.75);
    }
}

//
// Floating Text Box
//
%floating-box
{
    padding:10px;
    @extend %border;
    @extend %drop-shadow;
    background:rgba(255,255,255,0.9);

    //
    // Defaults
    //
    *
    {
        color:$color__copy;
    }

    a
    {
        text-decoration:none;

        &:after
        {
            content:none;
        }
    }
}

