/////////////////////////////////////////////////////////////////////////////
// CONTAO & CUSTOM MODULES e.g. mod_article, mod_header
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
// SYSTEM (A - Z)
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////
// MOD_ARTICLE
/////////////////////////////////////////

.mod_article
{
    position:relative;
    max-width:100%;

    &:first-of-type
    {
        @include rem( margin-top, spacer(0.5) );
    }
}

.template__full-width
{
    .mod_article > .inside
    {
        @include container;
        @include _use( clearfix );
    }
}

/////////////////////////////////////////
// MOD_NEWSLIST
/////////////////////////////////////////

.mod_newslist
{
    //
    // Display the latest offer
    //
    .layout_latest_offer
    {
        position:relative;

        //
        // Floating teaser box
        //
        .teaser-box
        {
            position:absolute;
            top:42px;
            width:375px;
            @extend %floating-box;

            @include breakpoint( 'small-only' )
            {
                position:relative;
                top:auto;
                left:auto  !important;
                right:auto !important;
                width:100%;
            }
        }

        // align left
        &.left .teaser-box
        {
            left:42px;
        }

        // align right
        &.right .teaser-box
        {
            right:42px;
        }

        .headline
        {
            font-size:23px;
            line-height:1.2;
            border-bottom:2px solid $color__copy;
        }

        //
        // Pricing
        //
        .prices
        {
            @include rem( margin-bottom, spacer(1) );

            // wraps .price-label and .price-box
            .price-box-container
            {
                @include _use( inline-block );

                & + .price-box-container
                {
                   @include rem( margin-left, spacer(1) );
                }
            }

            // text label
            .price-label
            {
                font-weight:700;
            }

            // the box containing the prices
            .price-box
            {
                padding:5px;
                color:#fff;
                font-size:36px;
                font-weight:700;

                &.price-one
                {
                    background:$color__secondary;
                }

                &.price-two
                {
                    background:$color__primary;
                }
            }
        }

        //
        // More Link
        //
        .more a
        {
            color:$color__secondary;
            text-transform:uppercase;
            transition:color .3s ease;

            &:hover
            {
                color:$color__greyscale--light;
            }
        }
    }
}

/////////////////////////////////////////
// MOD_NEWSREADER
/////////////////////////////////////////

.mod_newsreader
{
    .back
    {
        @include rem( margin, spacer(1) 0 );
    }

    .layout_full_offer
    {
        .offer-visual
        {
            position:relative;
        }

        //
        // Pricing
        //
        .prices
        {
            position:absolute;
            left:15px;
            bottom:20px;

            // wraps .price-label and .price-box
            .price-box-container
            {
                @include _use( inline-block );

                & + .price-box-container
                {
                    @include rem( margin-left, spacer(1) );
                }
            }

            // text label
            .price-label
            {
                font-weight:700;
            }

            // the box containing the prices
            .price-box
            {
                padding:5px;
                color:#fff;
                font-size:36px;
                font-weight:700;

                &.price-one
                {
                    background:$color__secondary;
                }

                &.price-two
                {
                    background:$color__primary;
                }
            }
        }
    }
}

/////////////////////////////////////////
// MOD_SEARCH
/////////////////////////////////////////

.mod_search {

    input {
        padding:6px 8px;
        border:0;
        border-radius:0;
        outline:0;
    }

    [type="search"] {
        background-color:#e3e3e3;
    }

    [type="submit"] {
        color:#fff;
        background:$color__secondary;
        transition:background-color .3s ease;

        &:hover
        {
            background:$color__primary;
        }
    }

    .header {
        margin-top:0.5rem;
        font-size:80%;
    }

    // search item
    & > div {
        padding:0.5rem 0;
    }

    & > div + div {
        border-top:1px solid #e8e8e8;
        margin-top:0.5rem;
    }

    .url {
        font-style:italic;
    }
}

/////////////////////////////////////////
// MOD_SITEMAP
/////////////////////////////////////////

.mod_sitemap
{

    //
    // Defaults
    //
    ul
    {
        margin:0;
        padding:0;
        list-style-type:none;
    }

    a
    {
        transition:color .3s ease;

        &:before
        {
            content:none;
        }

        &:hover
        {
            color:$color__secondary;
        }
    }

    //
    // Level
    //

    .level_1
    {
        & > li > a
        {
            text-transform:uppercase;
        }
    }

    .level_2
    {
        @include rem( padding, 0 spacer(2) );
        list-style-type:circle;

        a
        {
            color:$color__copy;

            &:hover
            {
                color:$color__secondary;
            }
        }
    }
}

/////////////////////////////////////////////////////////////////////////////
// CUSTOM (A - Z)
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////
// MASONRY TILES (JS Module)
/////////////////////////////////////////

.tiled-gallery
{
    figure
    {
        margin:0;
        line-height:0;
    }

    // allow image scaling > max-width
    img
    {
        width:100%;
    }

    .ce_text
    {
        transition:color .3s ease;

        // Put text, SVG content on top
        .text
        {
            z-index:100;
            pointer-events:none;
        }

        //
        // Animate the overlay, fill and color
        //
        &:hover
        {
            .inside:after
            {
                opacity:0;
            }

            &.overlay__orange
            {
                svg *
                {
                    fill:$color__primary;
                }

                *
                {
                    color:$color__primary;
                }
            }

            &.overlay__blue
            {
                svg *
                {
                    fill:$color__primary;
                }

                *
                {
                    color:$color__primary;
                }
            }

            &.overlay__green
            {
                svg *
                {
                    fill:$color__secondary;
                }

                *
                {
                    color:$color__secondary;
                }
            }
        }

        //
        // Use pseudo to overlay the images
        //
        .inside:after
        {
            content:'';
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            opacity:1;
            mix-blend-mode:multiply;
            transition:opacity .6s ease;
            pointer-events:none;
        }

        // Orange overlay
        &.overlay__orange
        {
            .inside:after
            {
                background-color:rgba( 255,162,0,0.99 );
            }
        }

        // blue overlay
        &.overlay__blue
        {
            .inside:after
            {
                background-color:rgba( 0,204,255,0.99 );
            }
        }

        // green overlay
        &.overlay__green
        {
            .inside:after
            {
                background-color:rgba( 118,255,133,0.99 );
            }
        }
    }

    //
    // Layout
    //

    // 2 Column Layout
    .col-2
    {
        @include span( 2 of 4 );
        margin-bottom:gutter();

        @include breakpoint( 'small-only' )
        {
            width:100%;
            float:none !important;
        }
    }

    // 4 Column Layout
    .col-4
    {
        @include span( 1 of 4 );

        @include breakpoint( 'small-only' )
        {
            width:100%;
            float:none !important;
            margin-bottom:gutter();
        }
    }

    .last
    {
        @include span( last );
    }
}

//
// Fallback for browser which don't support `mix-blend-mode`
// see: http://caniuse.com/#search=mix-blend-mode
//
.ie, .no-mix-blend-mode
{
    .overlay__orange
    {
        .inside:after
        {
            background-color:rgba( 225,132,0,0.8 ) !important;
        }
    }

    .overlay__blue
    {
        .inside:after
        {
            background-color:rgba( 0,184,235,0.8 ) !important;
        }
    }

    .overlay__green
    {
        .inside:after
        {
            background-color:rgba( 53,117,60,0.8 ) !important;
        }
    }
}

/////////////////////////////////////////
// MOD_ARTICLE_GREY
/////////////////////////////////////////

.mod_article_grey
{
    background:url( '../images/Backgrounds/grey-pattern.jpg' ) #dedede top center repeat-y;
}

//
// Slice Backgrounds for 2-Column Layout
//
.template__default
{
    .main-container
    {
        .mod_article_grey { background:url( '../images/Backgrounds/grey-pattern-main.jpg' ) #dedede top center repeat-y; }
    }

    .sidebar-container
    {
        .mod_article_grey { background:url( '../images/Backgrounds/grey-pattern-aside.jpg' ) #dedede top center repeat-y; }
    }
}

/////////////////////////////////////////
// MOD_ARTICLE_GREEN
/////////////////////////////////////////

.mod_article_green
{
    background:url( '../images/Backgrounds/green-pattern.jpg' ) #326d38 top center repeat-y;

    // override color for all elements
    *
    {
        color:#fff;
    }

    a
    {
        text-decoration:underline;

        &:hover
        {
            color:$color__primary;
        }
    }
}

//
// Slice Backgrounds for 2-Column Layout
//
.template__default
{
    .main-container
    {
        .mod_article_green { background:url( '../images/Backgrounds/green-pattern-main.jpg' ) #326d38 top center repeat-y; }
    }

    .sidebar-container
    {
        .mod_article_green { background:url( '../images/Backgrounds/green-pattern-aside.jpg' ) #326d38 top center repeat-y; }
    }
}

/////////////////////////////////////////
// MOD_ARTICLE_WHITE
/////////////////////////////////////////

.mod_article_white
{
    background:url( '../images/Backgrounds/white-pattern.jpg' ) #fff top center repeat-y;

    a
    {
        color:$color__secondary;
        text-decoration:underline;
        transition:color .3s ease;

        &:hover
        {

            color:$color__primary;
        }
    }
}

//
// Slice Backgrounds for 2-Column Layout
//
.template__default
{
    .main-container
    {
        .mod_article_white { background:url( '../images/Backgrounds/white-pattern-main.jpg' ) #fff top center repeat-y; }
    }

    .sidebar-container
    {
        .mod_article_white { background:url( '../images/Backgrounds/white-pattern-aside.jpg' ) #fff top center repeat-y; }
    }
}
