/////////////////////////////////////////////////////////////////////////////
// HELPER DEFINITIONS
//
// 1. Helpers are defined using the _add() mixin defined in _system.scss
// 2. Helpers may be used using the _use() mixin defined in _system.scss
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

//
// Image replacement
//
@include _add(ir)
{
    background-color:transparent;
    border:0;
    overflow:hidden;
    // IE 6/7 fallback
    *text-indent:-9999px;
    &:before
    {
        content:"";
        display:block;
        width:0;
        height:100%;
    }
}

//
// Hide from both screenreaders and browsers: h5bp.com/u
//
@include _add(hidden)
{
    display:none;
    visibility:hidden;
}

//
// Reset hidden
//
@include _add(show)
{
    display:block;
    visibility:visible;
}

//
// Hide only visually, but have it available for screenreaders: h5bp.com/v
//
@include _add(visually-hidden)
{
    border:0;
    clip:rect(0 0 0 0);
    height:1px;
    margin:-1px;
    overflow:hidden;
    padding:0;
    position:absolute;
    width:1px;

    // Extends the .visuallyhidden class to allow the element to be focusable
    // when navigated to via the keyboard: h5bp.com/p
    &.focusable:active,
    &.focusable:focus
    {
        clip:auto;
        height:auto;
        margin:0;
        overflow:visible;
        position:static;
        width:auto;
    }
}

//
// Hide visually and from screenreaders, but maintain layout
//
@include _add(invisible)
{
    visibility:hidden;
}

//
// Contain floats: h5bp.com/q
//
@include _add(clearfix)
{
    &:before,
    &:after
    {
        content:" ";
        display:table;
    }
    &:after
    {
        clear:both;
    }
    *zoom:1;
}

// from Ekino  ***** */

//
// Crossbrowser display:inline-block;
//
@include _add(inline-block)
{
    display:inline-block;
    .lt-ie8 &
    {
        display:inline;
    }
}

@include _add(vertical-align)
{
    &:before
    {
        @include _use(inline-block);
        vertical-align:middle;
        content:"";
        height:100%;
        width:0;
        margin:0;
    }
    @include _use(inline-block);
    vertical-align:middle;
}

@include _add(non-selectable)
{
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}