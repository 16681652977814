/////////////////////////////////////////////////////////////////////////////
// GLOBAL SASS Variables
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
//// COLOR
/////////////////////////////////////////////////////////////////////////////

//
// Greyscale Theme Color Variations
//
$color__greyscale--light:               #dedede;
$color__greyscale--medium:              #7d7d7d;
$color__greyscale--dark:                #494949;

//
// Primary Theme Color Variations
//
$color__primary:                        #35753c;
$color__primary--light:                 #52ae5c;
$color__primary--dark:                  #234f28;

//
// Secondary Theme Color Variations
//
$color__secondary:                      #ffa200;

//
// Tertiary Theme Color Variations
//
$color__tertiary:                       #00ccff;

//
// Hyperlink Color Theme Variations
//
$color__link:                           $color__primary;
$color__link--hover:                    $color__secondary;
$color__link--visited:                  $color__primary--dark;

//
// Copy
//
$color__copy:                           #7d7d7d;

//
// Background-Color Theme Variations
//
$color__background--body:               #fff;
$color__background--hr:                 #7d7d7d;

/////////////////////////////////////////////////////////////////////////////
//// TYPOGRAPHY
/////////////////////////////////////////////////////////////////////////////

$rem__base:                             14px;

//
// Font-Family Setup
//
$font__default:                         'Roboto Slab', serif;
$font__display:                         $font__default;
$font__display--alt:                    'Gudea', sans-serif;;
$font__icons:                           'adf-icons';

//
// Font-Size Variants
//
$font-size__default:                    $rem__base;

$font-size__title:                      20px;
$font-size__title--sub:                 $font-size__title;

$font-size__h1:                         $font-size__title;
$font-size__h2:                         $font-size__title;
$font-size__h3:                         $font-size__default;
$font-size__h4:                         $font-size__default;
$font-size__h5:                         $font-size__default;
$font-size__h6:                         $font-size__default;

//
// Line-Height Variants
//
$line-height__min:                      1;
$line-height__smaller:                  1.2;
$line-height__small:                    1.3;
$line-height__default:                  1.4;
$line-height__big:                      1.6;
$line-height__bigger:                   1.8;
$line-height__max:                      2.0;

/////////////////////////////////////////////////////////////////////////////
//// LAYOUT
/////////////////////////////////////////////////////////////////////////////

//
// Default Layout Variables
//
$container__max:                        930px;
$container__main:                       60.4%;
$container__aside:                      100% - $container__main;
$container__padding:                    15px;

/////////////////////////////////////////////////////////////////////////////
//// BREAKPOINTS
/////////////////////////////////////////////////////////////////////////////

//
// Map of available breakpoints, used for breakpoint() mixin defined in system.scss
//
$breakpoints:(
    'small':            600px,
    'small-only':       0px 899px,
    'medium-only':      900px 1199px,
    'container-width':  900px 960px,
    'medium':           900px,
    'large':            1200px
);

$breakpoint__small:     899px;
$breakpoint__medium:    899px;
$breakpoint__large:     1200px;