/////////////////////////////////////////////////////////////////////////////
// FONT LOADER
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
//// GOOGLE
/////////////////////////////////////////////////////////////////////////////

//@import url(http://fonts.googleapis.com/css?family=Alegreya+Sans:300,500,700|Alegreya:400italic,400);
@include importFont('../fonts/icons/', 'adf-icons', 'alldesign-dev-framework', 'alldesign-dev-framework');

/////////////////////////////////////////////////////////////////////////////
//// ICON FONT MAPPINGS
/////////////////////////////////////////////////////////////////////////////

//
// Font reference: http://app.fontastic.me/#customize/wh3VpbMeU8hDX73HNbzyXT
//
@include _add(adf-icons)
{
    font-family:$font__icons !important;
    font-style:normal !important;
    font-weight:normal !important;
    font-variant:normal !important;
    text-transform:none !important;
    speak:none;
    line-height:1;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}

// Ordered after http://app.fontastic.me/#customize/wh3VpbMeU8hDX73HNbzyXT
@include _add(angle-double-up)          { &:before { content:'a'; } }
@include _add(angle-down)               { &:before { content:'b'; } }
@include _add(angle-left)               { &:before { content:'c'; } }
@include _add(angle-right)              { &:before { content:'d'; } }
@include _add(angle-up)                 { &:before { content:'e'; } }
@include _add(align-justify)            { &:before { content:'f'; } }
@include _add(angle-double-down)        { &:before { content:'g'; } }
@include _add(angle-double-left)        { &:before { content:'h'; } }
@include _add(angle-double-right)       { &:before { content:'i'; } }
@include _add(left-open-big)            { &:before { content:'j'; } }
@include _add(right-open-big)           { &:before { content:'k'; } }
@include _add(facebook-circled)         { &:before { content:'r'; } }
@include _add(gplus-circled)            { &:before { content:'s'; } }
@include _add(linkedin-circled)         { &:before { content:'z'; } }
@include _add(pinterest-circled)        { &:before { content:'A'; } }
@include _add(rdio-circled)             { &:before { content:'B'; } }
@include _add(twitter-circled)          { &:before { content:'C'; } }
@include _add(vimeo-circled)            { &:before { content:'D'; } }
@include _add(tumblr-circled)           { &:before { content:'E'; } }
@include _add(up-open-big)              { &:before { content:'I'; } }
@include _add(down-open-big)            { &:before { content:'J'; } }
@include _add(chevron-down-circle)      { &:before { content:'K'; } }
@include _add(chevron-up-circle)        { &:before { content:'L'; } }
@include _add(delete-circle)            { &:before { content:'p'; } }
@include _add(globe-world)              { &:before { content:'q'; } }
@include _add(right)                    { &:before { content:'M'; } }
@include _add(zoom-in)                  { &:before { content:'O'; } }
@include _add(zoom-out)                 { &:before { content:'P'; } }
@include _add(minus-circle)             { &:before { content:'Q'; } }
@include _add(question)                 { &:before { content:'R'; } }
@include _add(plus-circle)              { &:before { content:'l'; } }
@include _add(minus)                    { &:before { content:'m'; } }
@include _add(plus)                     { &:before { content:'n'; } }
@include _add(link)                     { &:before { content:'t'; } }
@include _add(heart)                    { &:before { content:'u'; } }
@include _add(heart-empty)              { &:before { content:'v'; } }
@include _add(star-half)                { &:before { content:'w'; } }
@include _add(star-half-1)              { &:before { content:'x'; } }
@include _add(star-two)                 { &:before { content:'y'; } }
@include _add(star)                     { &:before { content:'F'; } }
@include _add(rss-two)                  { &:before { content:'G'; } }
@include _add(exclamation-circle)       { &:before { content:'H'; } }
@include _add(check-mark-circle)        { &:before { content:'S'; } }
@include _add(left-circle)              { &:before { content:'T'; } }
@include _add(information-circle)       { &:before { content:'U'; } }
@include _add(resize-down)              { &:before { content:'V'; } }
@include _add(resize-expand)            { &:before { content:'W'; } }
@include _add(refresh)                  { &:before { content:'X'; } }
@include _add(repeat-redo)              { &:before { content:'Y'; } }
@include _add(layout)                   { &:before { content:'o'; } }
@include _add(fast-backward)            { &:before { content:'N'; } }
@include _add(fast-forward)             { &:before { content:'U'; } }
@include _add(stop)                     { &:before { content:'1'; } }
@include _add(pause)                    { &:before { content:'2'; } }
@include _add(play)                     { &:before { content:'3'; } }
@include _add(share)                    { &:before { content:'4'; } }
@include _add(user)                     { &:before { content:'5'; } }
@include _add(user-add)                 { &:before { content:'6'; } }
@include _add(users)                    { &:before { content:'7'; } }
@include _add(mail-1)                   { &:before { content:'9'; } }
@include _add(chat)                     { &:before { content:'8'; } }
@include _add(picture)                  { &:before { content:'!'; } }
@include _add(youtube)                  { &:before { content:'"'; } }
@include _add(call-phone)               { &:before { content:'#'; } }
@include _add(calendar)                 { &:before { content:'$'; } }
@include _add(calendar-1)               { &:before { content:'%'; } }
@include _add(shopping-cart)            { &:before { content:'0'; } }
@include _add(home)                     { &:before { content:'&'; } }
@include _add(search-find)              { &:before { content:"'"; } }
@include _add(tag-2)                    { &:before { content:"("; } }
@include _add(tags)                     { &:before { content:")"; } }


/////////////////////////////////////////////////////////////////////////////
//// ICON FONT IMPLEMENTATIONS
/////////////////////////////////////////////////////////////////////////////

//
// Example <span class="adf-icons angle-double-up">Content</span>
//

[class^="adf-icons"],
[class*=" adf-icons"]
{
    @include _use(adf-icons);
}

// Ordered after ../fonts/icons/icons-reference.html
.adf-icons
{
    &.angle-double-up           { @include _use(angle-double-up); }
    &.angle-down                { @include _use(angle-down); }
    &.angle-left                { @include _use(angle-left); }
    &.angle-right               { @include _use(angle-right); }
    &.angle-double-down         { @include _use(angle-double-down); }
    &.angle-double-left         { @include _use(angle-double-left); }
    &.angle-double-right        { @include _use(angle-double-right); }
    &.left-open-big             { @include _use(left-open-big); }
    &.right-open-big            { @include _use(right-open-big); }
    &.facebook-circled          { @include _use(facebook-circled); }
    &.gplus-circled             { @include _use(gplus-circled); }
    &.linkedin-circled          { @include _use(linkedin-circled); }
    &.pinterest-circled         { @include _use(pinterest-circled); }
    &.rdio-circled              { @include _use(rdio-circled); }
    &.twitter-circled           { @include _use(twitter-circled); }
    &.vimeo-circled             { @include _use(vimeo-circled); }
    &.tumblr-circled            { @include _use(tumblr-circled); }
    &.up-open-big               { @include _use(up-open-big); }
    &.down-open-big             { @include _use(down-open-big); }
    &.chevron-down-circle       { @include _use(chevron-down-circle); }
    &.chevron-up-circle         { @include _use(chevron-up-circle); }
    &.delete-circle             { @include _use(delete-circle); }
    &.globe-world               { @include _use(globe-world); }
    &.right                     { @include _use(right); }
    &.zoom-in                   { @include _use(zoom-in); }
    &.zoom-out                  { @include _use(zoom-out); }
    &.minus-circle              { @include _use(minus-circle); }
    &.question                  { @include _use(question); }
    &.plus-circle               { @include _use(plus-circle); }
    &.minus                     { @include _use(minus); }
    &.plus                      { @include _use(plus); }
    &.link                      { @include _use(link); }
    &.heart                     { @include _use(heart); }
    &.heart-empty               { @include _use(heart-empty); }
    &.star-half                 { @include _use(star-half); }
    &.star-half-1               { @include _use(star-half-1); }
    &.star-two                  { @include _use(star-two); }
    &.star                      { @include _use(star); }
    &.rss-two                   { @include _use(rss-two); }
    &.exclamation-circle        { @include _use(exclamation-circle); }
    &.check-mark-circle         { @include _use(check-mark-circle); }
    &.left-circle               { @include _use(left-circle); }
    &.information-circle        { @include _use(information-circle); }
    &.resize-down               { @include _use(resize-down); }
    &.resize-expand             { @include _use(resize-expand); }
    &.refresh                   { @include _use(refresh); }
    &.repeat-redo               { @include _use(repeat-redo); }
    &.layout                    { @include _use(layout); }
    &.fast-backward             { @include _use(fast-backward); }
    &.fast-forward              { @include _use(fast-forward); }
    &.home                      { @include _use(home); }
    &.stop                      { @include _use(stop); }
    &.pause                     { @include _use(pause); }
    &.play                      { @include _use(play); }
    &.share                     { @include _use(share); }
    &.user                      { @include _use(user); }
    &.user-add                  { @include _use(user-add); }
    &.users                     { @include _use(users); }
    &.mail-1                    { @include _use(mail-1); }
    &.chat                      { @include _use(chat); }
    &.picture                   { @include _use(picture); }
    &.youtube                   { @include _use(youtube); }
    &.call-phone                { @include _use(call-phone); }
    &.calendar                  { @include _use(calendar); }
    &.calendar-1                { @include _use(calendar-1); }
    &.shopping-cart             { @include _use(shopping-cart); }
    &.home                      { @include _use(home); }
    &.search-find               { @include _use(search-find); }
    &.tag-2                     { @include _use(tag-2); }
    &.tags                      { @include _use(tags); }
}

