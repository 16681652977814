/////////////////////////////////////////////////////////////////////////////
// SYSTEM TOOLS
//
// Based on the great DoCSSa – Sass based CSS architecture and methodology
// Github: https://github.com/mlarcher/docssa
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
//// SYSTEM VARIABLES
/////////////////////////////////////////////////////////////////////////////

// map of available modules, used by define()
$modules:() !default;

// map of available placeholders, used by _add()
$placeholders:();

// default breakpoint values, used by breakpoint()
$default-breakpoint:root;
$current-breakpoint:$default-breakpoint;

/////////////////////////////////////////////////////////////////////////////
//// HELPER FUNCTION
/////////////////////////////////////////////////////////////////////////////

//
// Strips unit of passed value
//
@function strip-unit( $n )
{
    @return $n / ( $n * 0 + 1 );
}


//
// Uses $rem__base to calculate consistent spacings
// for margin/padding and positioning in pixel
//
@function spacer( $n : 1 )
{
    @return ( $rem__base * $n );
}

/////////////////////////////////////////////////////////////////////////////
//// DEFINE
/////////////////////////////////////////////////////////////////////////////

//
// this mixin makes it possible to have a file imported at multiple place and
// only be output the first time it is called. it is used for placeholders to
// prevent them from being repeated by each file depending on them
//
@mixin define($uniqeRef)
{
    @if not index( $modules, $uniqeRef )
    {
        $modules:append($modules, $uniqeRef);
        @content;
    }
}

/////////////////////////////////////////////////////////////////////////////
//// BREAKPOINT
/////////////////////////////////////////////////////////////////////////////

//
// this mixin needs to be used to enter a @media that will let use use cross-media placeholders
// ex:
//@include breakpoint(medium) {
//  .selector {
//    @include _use(clearfix);
//  }
//}

@mixin breakpoint($breakpoint)
{
    // Get the width from the keyword `$breakpoint`
    // Or `null` if the keyword doesn't exist in `$breakpoints` map
    $value:map-get($breakpoints, $breakpoint);

    // If `$breakpoint` exists as a key in `$breakpoints`
    @if $value != null
    {
        // Update `$current-breakpoint`
        $current-breakpoint:$breakpoint !global;

        @if length( $value ) == 2
        {
            // Open a media query block
            @media (min-width:nth($value, 1)) and (max-width:nth($value, 2)) {
                // Let the user dump content
                @content;
            }
        }
        @else if length( $value ) == 1
        {
            // Open a media query block
            @media (min-width:$value)
            {
                // Let the user dump content
                @content;
            }
        }
        @else
        {
            @warn "Too many values in `#{$value}`! Max length is 2.";
        }

        // Then reset `$current-breakpoint` to `$default-breakpoint` (root)
        $current-breakpoint:$default-breakpoint !global;
    }

        // If `$breakpoint` doesn't exist in `$breakpoints`,
        // Warn the user and do nothing
    @else
    {
        @warn "Invalid breakpoint `#{$breakpoint}`.";
    }
}

/////////////////////////////////////////////////////////////////////////////
//// PLACEHOLDER
/////////////////////////////////////////////////////////////////////////////

@mixin _add($name)
{
    // If placeholder doesn't exist yet in `$placeholders` list
    @if not index($placeholders, $name)
    {
        // Store its name
        $placeholders:append($placeholders, $name) !global;

        // At root level
        @at-root
        {
            // Looping through `$breakpoints`
            @each $breakpoint, $value in $breakpoints
            {
                @if length( $value ) == 2
                {
                    // Open a media query block
                    @media (min-width:nth($value, 1)) and (max-width:nth($value, 2)) {
                        // set the current-breakpoint so that nested calls to _use can work
                        $current-breakpoint:$breakpoint !global;

                        // Generating a placeholder
                        // Called $name-$breakpoint
                        %#{$name}-#{$breakpoint}
                        {
                            // dump the content
                            @content;
                        }

                        // reset current-breakpoint
                        $current-breakpoint:$default-breakpoint !global;
                    }
                }
                @else if length( $value ) == 1
                {
                    // Open a media query block
                    @media (min-width:$value)
                    {
                        // set the current-breakpoint so that nested calls to _use can work
                        $current-breakpoint:$breakpoint !global;

                        // Generating a placeholder
                        // Called $name-$breakpoint
                        %#{$name}-#{$breakpoint}
                        {
                            // dump the content
                            @content;
                        }

                        // reset current-breakpoint
                        $current-breakpoint:$default-breakpoint !global;
                    }
                }
                @else
                {
                    @warn "Too many values in `#{$value}`! Max length is 2.";
                }
            }

            // And dumping a placeholder out of any media query as well
            // so basically at root level
            %#{$name}
            {
                @content;
            }
        }
    }

        // If placeholder already exists, just warn the user
    @else
    {
        @warn "Placeholder `#{$name}` already exists.";
    }
}

/////////////////////////////////////////////////////////////////////////////
//// PLACEHOLDER IMPLEMENTER
/////////////////////////////////////////////////////////////////////////////

//
// Implements SASS placeholder added to `$placeholder` map by _add()
//
@mixin _use($name)
{
    @if $current-breakpoint == $default-breakpoint
    {
        @extend %#{$name} !optional;
    }
    @else
    {
        @extend %#{$name}-#{$current-breakpoint} !optional;
    }
}
