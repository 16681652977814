/////////////////////////////////////////////////////////////////////////////
// MIXINS
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
//// FONTS
/////////////////////////////////////////////////////////////////////////////

//
// mixins assumes fonts are located under assets/fonts
// usagage: @include importFont('../fonts/', 'titillium', 'titilliumweb-regular-webfont', 'titillium_webregular');
//
@mixin importFont($path, $fontname ,$filename, $svgLabel, $isBold:false, $isItalic:false)
{
    @font-face
    {
        font-family:$fontname;

        // IE9 Compat Modes
        src:url('#{$path}#{$filename}.eot');

        // IE6-IE8
        src:url('#{$path}#{$filename}.eot?#iefix') format('embedded-opentype'),

        // Modern Browsers offers a 30% average gain over WOFF 1.0
        url('#{$path}#{$filename}.woff2') format('woff2'),
        // Modern Browsers

        url('#{$path}#{$filename}.woff') format('woff'),

        // Safari, Android, iOS
        url('#{$path}#{$filename}.ttf') format('truetype'),

        // Legacy iOS
        url('#{$path}#{$filename}.svg##{$svgLabel}') format('svg');

        @if $isBold
        {
            font-weight:bold;
        }
        @else
        {
            font-weight:normal;
        }

        @if $isItalic
        {
            font-style:italic;
        }
        @else
        {
            font-style:normal;
        }
    }
}

/////////////////////////////////////////////////////////////////////////////
//// REM CONVERSION - by https://gist.github.com/davidensinger/5217667
/////////////////////////////////////////////////////////////////////////////

// usage: @include rem( margin, 15 10 8 20 );

@mixin rem($property, $values, $important : false)
{
    // Create a couple of empty lists as output buffers.
    $font-size:$font-size__default;
    $px-values:();
    $rem-values:();

    // Loop through the $values list
    @each $value in $values
    {
        // For each property value, if it's in rem or px, derive both rem and
        // px values for it and add those to the end of the appropriate buffer.
        // Ensure all pixel values are rounded to the nearest pixel.
        @if $value == 0 or $value == 0px
        {
            // 0 -- use it without a unit
            $px-values:join($px-values, 0);
            $rem-values:join($rem-values, 0);
        }
        @else if type-of($value) == number and not unitless($value) and (unit($value) == px)
        {
            // px value given - calculate rem value from font-size
            $new-rem-value:$value / $font-size;
            $px-values:join($px-values, round($value));
            $rem-values:join($rem-values, #{$new-rem-value}rem);
        }
        @else if type-of($value) == number and not unitless($value) and (unit($value) == "%")
        {
            // % value given - don't add px or rem
            $px-values:join($px-values, #{$value});
            $rem-values:join($rem-values, #{$value});
        }
        @else if $value == auto
        {
            // auto - don't add px or rem
            $px-values:join($px-values, auto);
            $rem-values:join($rem-values, auto);
        }
        @else
        {
            // unitless value - use those directly as rem and calculate the px-fallback
            $px-values:join($px-values, round($value * $font-size));
            $rem-values:join($rem-values, #{$value}rem);
        }
    }

    // output the converted rules
    @if $px-values == $rem-values
    {
        @if $important == true
        {
            #{$property}:$px-values !important;
        }
        @else
        {
            #{$property}:$px-values;
        }
    }

    @else
    {
        @if $important == true
        {
            #{$property}:$px-values !important;
            #{$property}:$rem-values !important;
        }
        @else
        {
            #{$property}:$px-values;
            #{$property}:$rem-values;
        }

    }
}

/////////////////////////////////////////////////////////////////////////////
//// HELPERS
/////////////////////////////////////////////////////////////////////////////

@mixin box-sizing($box-model)
{
    -webkit-box-sizing:$box-model; // Safari <= 7
    -moz-box-sizing:$box-model; // Firefox <= 19
    box-sizing:$box-model;
}
