/////////////////////////////////////////////////////////////////////////////
// CONTAO & CUSTOM ELEMENTS e.g. ce_text, ce_headline, etc.
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
// SYSTEM (A - Z)
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////
// CE_ACCORDION
/////////////////////////////////////////

.ce_accordion
{
   & + .ce_accordion
   {
       margin-top:5px;
   }

    &.last .toggler
    {
        border-bottom:none;
    }

    .toggler
    {
        position:relative;
        padding:7px 0;
        border-bottom:1px solid $color__greyscale--medium;
        font-size:14px;
        font-weight:700;
        text-transform:uppercase;
        cursor:pointer;

        &:focus
        {
            outline:0;
        }

        &:after
        {
            content:'»';
            position:absolute;
            top:0;
            margin-left:3px;
            font-size:31px;
            line-height:1;
            color:$color__secondary;
            transition:all .3s ease;
        }

        &.active
        {
            &:after
            {
                opacity:0;
            }
        }
    }
}

/////////////////////////////////////////
// CE_DOWNLOAD / CE_DOWNLOADS
/////////////////////////////////////////

.ce_download, .ce_downloads
{
    ul
    {
        margin:0;
        padding:0;
    }

    li
    {
        list-style-type:none;

        & + li
        {
            margin-top:5px;
        }
    }

    a
    {
        @include _use( inline-block );
        vertical-align:middle;
        color:$color__copy;

        // remove default pseudo >>
        &:before { content:none; }

        &:hover
        {
            color:$color__primary;
        }
    }

    .mime_icon
    {
        @include _use( inline-block );
        vertical-align:baseline;
        margin-right:5px;
        padding:1px 5px 0 5px;
        font-size:75%;
        text-transform:uppercase;
        color:#fff;
        background:$color__secondary;

        &.pdf
        {
            background:#c6261b;
        }
    }
}

/////////////////////////////////////////
// CE_FORM
/////////////////////////////////////////

.ce_form
{
    font-family:$font__display;

    input:not([type="checkbox"])
    {
        width:100%;
        padding:5px;
        color:$color__copy;
        border:0;
        border-radius:0;
        outline:0;
        background:transparent;
        border-bottom:2px solid $color__greyscale--medium;
        transition:all .3s ease;

        &:active, &:focus
        {
            // Webkit
            ::-webkit-input-placeholder { color:$color__secondary; }

            // Firefox 19+
            ::-moz-placeholder { color:$color__secondary; }

            // IE10+
            :-ms-input-placeholder { color:$color__secondary; }

            color:$color__secondary;
            border-color:$color__secondary;
        }
    }

    textarea
    {
        width:100%;
        padding:5px;
        color:$color__copy;
        border:0;
        border-radius:0;
        outline:0;
        background:$color__greyscale--light;
        transition:all .3s ease;

        &:active, &:focus
        {
            // Webkit
            ::-webkit-input-placeholder { color:$color__secondary; }

            // Firefox 19+
            ::-moz-placeholder { color:$color__secondary; }

            // IE10+
            :-ms-input-placeholder { color:$color__secondary; }

            color:$color__secondary;
        }
    }

    // wraps each field
    .widget
    {
        label {
            display:none;
        }

        @include _use( clearfix );

        & + .widget
        {
            @include rem( margin-top, spacer(1) );
        }

       &-captcha
       {
           .captcha_text
           {
               display:block;
               font-size:90%;
               padding-left:6px;
           }
       }
    }

    .widget-checkbox {

        label {
            display:block;
            margin-left:0.5rem;
        }

        fieldset {
            border:0;
            margin:0;
            padding:0;
        }

        fieldset span {
            display:flex;
            align-items:center;
        }
    }

    input
    {
        &.captcha
        {
            @include _use( inline-block );
            width:100%;
            max-width:60%;
        }

        &[type='submit']
        {
            @include _use( inline-block );
            width:100%;
            max-width:105px;
            float:right;
            color:#fff;
            border-bottom:none;
            background:$color__secondary;
            transition:background-color .3s ease;

            &:hover
            {
                background:$color__primary;
            }
        }
    }

}

/////////////////////////////////////////
// CE_GALLERY
/////////////////////////////////////////

.ce_gallery
{
    @include rem( margin-top, spacer(1) );
    @include rem( margin-bottom, spacer(1) );

    //
    // Defaults
    //
    ul, ol
    {
        margin:0;
        padding:0;
        @include container;
        @include _use( clearfix );
    }

    li
    {
        list-style:none;
    }

    figure
    {
        margin:0;
    }

    //
    // Default gallery
    //
    $counter  : 0;
    $min-cols : 1;
    $max-cols : 12;

    @for $counter from $min-cols through $max-cols
    {
        .cols_#{$counter} li
        {
            @include gallery(1 of $counter );
            margin-bottom:gutters();

            @include breakpoint( 'small-only' )
            {
                @include gallery(1 of 2 );
            }
        }
    }

    // remove margin-right on medium devices and larger
    @include breakpoint( 'medium' )
    {
        .col_last
        {
            margin-right:0 !important;
        }
    }


    //
    // Masonry gallery
    //
    // module: js/src/components/masonry-gallery.js
    // implementation: js/src/index.js
    //
    &._is_masonry
    {
        margin:auto;
        @include masonry-tiles();
    }

    //
    // Partner logo gallery
    //
    // uses sass-susy for layout
    //
    &.partner-logos
    {
        margin:auto;

        li
        {
            @include gallery(1 of 9);
            @include rem( margin-bottom, spacer(2) );

            @include breakpoint( 'small-only' )
            {
                @include gallery(1 of 3);
                text-align:center;
            }
        }

        img
        {
            display:inline-block;
            vertical-align:middle;
        }
    }
}

/////////////////////////////////////////
// CE_IMAGE
/////////////////////////////////////////

.ce_image
{
    figure
    {
        // remove spacings
        margin:0;
        font-size:0;
    }
}

/////////////////////////////////////////
// CE_TABLE
/////////////////////////////////////////

.ce_table
{
    position:relative;

    tr
    {
        & + tr
        {
            border-top:1px solid $color__copy;
        }
    }

    td
    {
        @include rem( padding, spacer(0.5) );
        vertical-align:middle;

        &.col_first
        {
            font-weight:500;
        }
    }
}

/////////////////////////////////////////
// CE_TEXT
/////////////////////////////////////////

.ce_text
{
    @include _use( clearfix );

}


.main-container
{
    .ce_text p:first-of-type
    {
        @include rem( font-size, 16px );
        line-height:$line-height__default;
    }
}

/////////////////////////////////////////
// CE_TEXT_BORDER
/////////////////////////////////////////

//
// Text element with a grunge-style border
//
.ce_text_border
{
    position:relative;
    border-style:solid;
    border-width:40px;
    border-image:url( '../images/Misc/border-image.png' ) 40 round;
    text-align:center;

    .inside
    {
        padding:10px;
        background-color:#fcfcfc;
    }

    *
    {
        color:$color__primary;
    }

    h1, h2, h3,
    h4, h5, h6
    {
        @include _use( inline-block );
        padding:8px;
        @include rem( font-size, 14px );
        text-transform:uppercase;
        color:#fff;
        background:$color__primary;
    }
}

/////////////////////////////////////////////////////////////////////////////
// CUSTOM (A - Z)
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////
// SVG Service icons
/////////////////////////////////////////

//
// Defaults
//
.service-icon
{
    &__svg *
    {
        fill:#fff;
        transition:fill .3s ease;
    }

    &__title
    {
        margin-top:10px;
        font-size:16px;
        color:#fff;

        @include breakpoint( 'small-only' )
        {
            margin-top:18px;
            font-size:22px;
        }
    }
}
