/////////////////////////////////////////////////////////////////////////////
// ANIMATION Library
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
//// GROW VERTICAL
/////////////////////////////////////////////////////////////////////////////

@mixin animation__grow-vertical( $duration, $delay, $timing, $origin : (0 100%) )
{
    transform-origin:$origin;
    animation:grow-vertical $duration $delay $timing;
}

@keyframes grow-vertical
{
    from
    {
        transform:scale(1,0);
    }
    to
    {
        transform:scale(1,1);
    }
}