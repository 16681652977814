/////////////////////////////////////////////////////////////////////////////
// DEFAULT STYLES
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

/*///////////////////////////////////////////////////////////////////////////
//// HTML DEFAULTS
////////////////////////////////////////////////////////////////////////////*/

*,
*:before,
*:after
{
    @include box-sizing(border-box);
}

html
{
    width:100%;
    height:100%;
    font-size:100%;
}

body
{
    width:100%;
    min-height:100%;
    font-family:$font__default;
    font-size:$font-size__default;
    line-height:$line-height__bigger;
    color:$color__copy;
    background:$color__background--body;
}

//////////////////////////////////////////////////
//// TYPOGRAPHY
//////////////////////////////////////////////////

//////////////////////////////
// HEADLINES
//////////////////////////////

h1
{
    margin-top:0;
    font-family:$font__display;
    font-weight:700;
    @include rem(font-size, $font-size__h1);
    text-transform:uppercase;
    line-height:$line-height__small;
}

h2
{
    margin-top:0;
    font-family:$font__display;
    font-weight:700;
    @include rem(font-size, $font-size__h2);
    line-height:$line-height__small;
}

h3, h4, h5, h6
{
    font-weight:700;
    @include rem( margin-bottom, 0 );
    @include rem(font-size, $font-size__h3);
}

//////////////////////////////
// COPY
//////////////////////////////

p
{
    margin:0;
    font-size:inherit;

    & + &
    {
        @include rem( margin-top, spacer(1) );
    }
}

strong, b
{
    font-family:$font__display;
    font-weight:700;
}

small
{
    font-size:90%;
}

a
{
    font-size:inherit;
    color:$color__link;
    text-decoration:none;

    &:hover
    {
        color:$color__link--hover;
    }

    &:visited
    {
        color:$color__link--visited;
    }
}

ul, ol
{
    padding-left:15px;

    li
    {
        font-family:$font__display;
        font-weight:500;
        line-height:1.8;
    }
}

blockquote
{
    display:table;
    @include rem( margin, spacer(1) 0 );
    @include rem( font-size, 24px );
    color:$color__primary;
    line-height:$line-height__smaller;

    & > *
    {
        margin:0;
    }

    &:before
    {
        content:'»';
        @include rem( padding-right, spacer(1) );
        font-family:$font__display;
        font-weight:700;
        line-height:0.35;
        display:table-cell;
        vertical-align:top;
        @include rem( font-size, 130px );
    }

    p
    {
        display:table-cell;
        vertical-align:top;
    }
}

//////////////////////////////////////////////////
//// TABLES
//////////////////////////////////////////////////

table
{
    margin:0 0 1.5em;
    width:100%;
    font-family:$font__display;
}

th
{
    font-weight:bold;
}

td
{
    vertical-align:top;
}

//////////////////////////////////////////////////
//// DIVIDER
//////////////////////////////////////////////////

hr
{
    background-color:$color__background--hr;
    border:0;
    height:1px;
    margin-bottom:1.5em;
}

//////////////////////////////////////////////////
//// MEDIA
//////////////////////////////////////////////////

img
{
    max-width:100%;
    height:auto;
}

figure
{
    @include rem( margin, spacer(1) 0 );

    a
    {
        transition:opacity .3s ease;

        &:hover
        {
            opacity:0.8;
        }
    }
}

figcaption
{
    width:auto !important; // contao overwrite
    font-size:90%;
    text-align:right;
}

/*////////////////////////////////////////////////////////////////////////////
//// SYSTEM DEFAULTS
////////////////////////////////////////////////////////////////////////////*/

.invisible
{
    @include _use(visually-hidden);
}

//////////////////////////////////////////////////
//// EDITOR STYLES
//////////////////////////////////////////////////

.float_left
{
    display:inline;
    float:left;
    @include rem( margin, 0 spacer(1) spacer(1) 0 );

    & ~ ul,
    & ~ ol
    {
        @include _use( inline-block );
        vertical-align:top;
    }

    @include breakpoint( 'small-only' )
    {
        float:none;
    }
}

.float_right
{
    display:inline;
    float:right;
    @include rem( margin, 0 0 spacer(1) spacer(1) );

    @include breakpoint( 'small-only' )
    {
        float:none;
    }
}

/////////////////////////////////////////////////////////////////////////////
//// FRAMEWORK DEFAULTS
/////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////
//// ACCESSIBILITY
//////////////////////////////////////////////////

.screen-reader-text
{
    @include _use(visually-hidden);
}

//////////////////////////////////////////////////
//// DEVICE CONTROL
//////////////////////////////////////////////////

//
// Hides elements depending on window size
//
.show
{
    //
    // Small screens 100px - 899px
    //
    &__small--only
    {
        @include _use(hidden);
        @include breakpoint('small-only')
        {
            @include _use(show);
        }
    }

    //
    // Medium screens 900px
    //
    &__medium--up
    {
        @include _use(hidden);
        @include breakpoint('medium')
        {
            @include _use(show);
        }
    }

    //
    // Medium screens 900px - 1199px
    //
    &__medium--only
    {
        @include _use(hidden);
        @include breakpoint('medium-only')
        {
            @include _use(show);
        }
    }
}


//////////////////////////////////////////////////
//// CUSTOM EDITOR STYLES
//////////////////////////////////////////////////
