/////////////////////////////////////////////////////////////////////////////
// PAGES
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
/////////////////////////////////////////////////////////////////////////////

//
// The homepage needs special treatment
// so the css is rather big! :(
//
.cms_home
{
    //
    // Default `floating-box`headline
    //
    .ce_headline
    {
        position:absolute;
        left:50%;
        @extend %floating-box;
        @include rem( font-size, $font-size__default );
        white-space:nowrap;
        line-height:1;
        text-transform:uppercase;
        color:$color__primary;
        background:#fff;
        transform:translateX( -50% );
    }

    //
    // Header Gallery
    //
    .tiled-gallery
    {
        margin:0 auto;
        padding:18px 0;

        //
        // Typo & Layout
        //
        .col-2
        {
            .service-icon__title
            {
                margin-top:18px;
                font-size:22px;
            }
        }

        //
        // Icon & Text positioning
        //
        .ce_text
        {
            position:relative;

            .text
            {
                position:absolute;
                top:50%;
                left:50%;
                text-align:center;
                transform:translate( -50%, -50% );
            }
        }
    }

    //
    // Offer section
    //
    #offer
    {
        margin-top:100px;
        height:410px;

        @include breakpoint( 'small-only' )
        {
            height:auto;
        }

        // position the headline to intersect the bottom
        // of the header gallery images
        .ce_headline
        {
            top:-130px;

            @include breakpoint( 'small-only' )
            {
                top:-30px;
            }
        }

        // move the news list to vertically center the teaser image
        .mod_newslist
        {
            margin-top:-90px;

            @include breakpoint( 'small-only' )
            {
                margin-top:auto;
            }
        }
    }

    //
    // Partner logo section
    //
    #partner
    {
        margin-top:100px;

        // position the headline to intersect the bottom
        // of the offer teaser image
        .ce_headline
        {
            top:-90px;

            @include breakpoint( 'small-only' )
            {
                top:-60px;
            }
        }
    }
}

//
// Über Uns
//
.cms_about-us
{
    .ce_text_border
    {
        li
        {
            color:$color__primary;
            font-size:40px;
            list-style-type:none;
            line-height:1.1;

            small { font-size:60%; }
        }

        // move helmet visual
        figure
        {
            position:absolute;
            bottom:-120px;
            right:-20px;
        }
    }

}

//
// Leistungen > Arbeitsschutz
//
.cms_osh
{
    .ce_text_border
    {
        // move helmet visual
        figure
        {
            position:absolute;
            bottom:-120px;
            right:-60px;
        }
    }
}

.cms_contact
{
    .sidebar-container
    {
        h1
        {
            font-size:16px;
        }
    }
}

//
// Impressum
//
.cms_imprint
{
    .ce_text
    {
        p:first-of-type
        {
            font-size:$font-size__default;
            line-height:1.8;
        }

        p + h2
        {
            @include rem( margin-top, spacer(1) );
        }

        h2
        {
            font-size:16px;
            margin-bottom:0;
        }
    }
}

//
// Datenschutz
//
.cms_data-protection {
    .ce_text
    {
        p:first-of-type
        {
            font-size:$font-size__default;
            line-height:1.8;
        }

        p + h2
        {
            @include rem( margin-top, spacer(1) );
        }

        h2
        {
            font-size:16px;
            margin-bottom:0;
        }
    }
}

//
// Angebote (temp)
//
@media(max-width:969px) {
    .cms_offers .bosch-banner img {
        float:none !important;
    }
}
