////////////////////////////////////////////////////////////////////
// NAVIGATION COMPONENTS
//
// @author     Christian Hamacher <c.hamacher@*.de>
// @copyright  Alldesign, www.alldesign.de
// @version    1.0.0
////////////////////////////////////////////////////////////////////

//
// Top Bar - medium--up
//
$top-bar__height:auto;
$top-bar__bg:#fff;

//
// Tab Bar - small--only
//
$tab-bar__height:59px;
$tab-bar__bg:#fff;

//
// Off Canvas Nav - small--only
//
$off-canvas__width:350px;

////////////////////////////////////////////////////////////////////
//// SYSTEM NAVIGATION
////////////////////////////////////////////////////////////////////

.system-navigation {
    display:inline-block;
    margin-left:auto;

    ul {
        display:flex;
        margin:0;
        padding:0;
        list-style-type:none;
        color:$color__copy;
        font-size:90%;
    }

    li {
    }

    li + li {
        &:before {
            content:'|';
            padding:0 0.5rem;
        }
    }

    a {
        font-family:$font__display--alt;
        color:$color__copy;

        &:hover {
            color:$color__secondary;
        }
    }
}

////////////////////////////////////////////////////////////////////
//// MAIN NAVIGATION
////////////////////////////////////////////////////////////////////

.main-nav-container {
    position:relative;
    top:0;
    width:100%;
    background:$top-bar__bg;
    z-index:999;

    @include breakpoint('small-only') {
        background:$tab-bar__bg;
    }
}

//
// Default Main Navigation Styles
//
.main-nav {
    //
    // Navigation Sections
    //
    &__section {
        position:relative;

        //
        // Site title & Logo
        //
        &--branding {
            .logo-container {
                line-height:0;
            }

            .name {
                @include _use(visually-hidden);
            }
        }

        //
        // Navigation Menu
        //
        &--menu {
            ul {
                margin:0;
                padding:0;
            }

            //
            // Defaults
            //
            li {
                list-style:none;
                font-family:$font__display--alt;
                @include rem(font-size, 16px);
            }
        }
    }
}

/////////////////////////////////////////
//// TOP BAR - medium ++ (min-width: 900px)
/////////////////////////////////////////

.top-bar {
    height:$top-bar__height;

    //
    // Navigation Menu
    //
    .main-nav__section--menu {
        padding:25px 0 20px 0;

        //
        // Main Level
        //
        .level_1 {
            & > li {
                position:relative;

                // align the menu items/logo vertically
                // because of the inline-block font-size is set to 0
                // to minimize the gaps between the elements
                @include _use(inline-block);
                vertical-align:middle;
                font-size:0;

                & + li {
                    margin-left:52px;

                    // adjust the margin if window <= 960px
                    @include breakpoint('container-width') {
                        margin-left:42px;
                    }
                }

                & > a,
                & > span {
                    display:block;
                    color:$color__copy;
                    text-transform:uppercase;
                    transition:all .3s ease;
                    font-size:16px;

                    &:hover {
                        color:$color__primary;
                    }

                    &.trail,
                    &.active {
                        color:$color__primary;
                    }
                }

                // active 1st level item
                &.trail,
                &.active {
                    color:$color__primary;
                }

                // logo
                &.branding {
                    line-height:0;
                }
            }

            // show dropdown
            .submenu:hover .level_2 {
                visibility:visible;
                opacity:1;
            }

        }

        //
        // Sub Level
        //
        .level_2 {
            position:absolute;
            left:- (spacer(1));
            visibility:hidden;
            opacity:0;
            padding-top:50px;
            transition:opacity .5s ease-in-out;

            li {
                padding:5px 20px;
                background:#fff;
                line-height:1;

                &.last {
                    padding-bottom:15px;
                }

            }

            a, span {
                font-size:$font-size__default;
                color:$color__copy;
                font-weight:700;
                letter-spacing:1px;
                text-transform:uppercase;
                transition:color .3s ease;

                &:hover,
                &.trail,
                &.active {
                    color:$color__secondary;
                }
            }
        }
    }
}

// show default navigation if transform3D is not supported
.no-csstransforms3d .top-bar {
    display:block !important;
    visibility:visible !important;

    // customize layout
    @include breakpoint('small-only') {
        a, span {
            font-size:14px !important;
        }

        .mod_navigation {
            text-align:center;
        }

        .level_1 {
            display:inline-block;
            vertical-align:top;

            & > li + li {
                margin-left:15px !important;
            }
        }

        .level_2 {
            text-align:left;
        }

        .logo-container {
            text-align:center;

            img {
                width:50% !important;
            }
        }
    }
}

/////////////////////////////////////////
//// TAB BAR - small only (max-width: 899px)
/////////////////////////////////////////

.tab-bar {
    height:$tab-bar__height;

    //
    // Site title & Logo
    //
    .main-nav__section--branding {
        width:100px;
        float:left;

        img {
            margin:14px 0 0 5px;
            width:60%;
        }
    }

    //
    // Navigation Menu
    //
    .main-nav__section--menu {
        float:right;

        .menu-icon {
            position:relative;
            display:table-cell;
            vertical-align:middle;
            background:none;
            border:none;
            outline:0;
        }

        .hamburger, .hamburger:before, .hamburger:after {
            content:'';
            position:relative;
            display:block;
            @include rem(width, 35px);
            @include rem(height, 5px);
            font-size:0;
            color:transparent;
            background:$color__primary;
            cursor:pointer;
            transition:all .5s ease-in-out;
        }

        // move bars
        .hamburger {
            &:before {
                @include rem(top, -10px);
            }
            &:after {
                @include rem(top, 5px);
            }
        }

        .off-canvas-back {
            @include rem(padding-right, 5px);
            color:$color__primary;
            @include rem(font-size, 50px);
            visibility:hidden;
            opacity:0;
            transition:all .3s ease-in-out;

            &:hover {
                color:$color__secondary;
            }
        }

        //
        // Active State
        //
        ._is_active {
            .hamburger {
                background-color:transparent;

                &:before {
                    top:0;
                    transform:rotate(45deg)
                }

                &:after {
                    @include rem(top, -5px);
                    transform:rotate(-45deg)
                }
            }

            &.off-canvas-back {
                visibility:visible;
                opacity:1;
            }
        }

    }
}

// hide tab-bar navigation if transform3D is not supported
.no-csstransforms3d .tab-bar {
    display:none !important;
    visibility:hidden !important;
}

/////////////////////////////////////////
//// OFF CANVAS NAV - small only (max-width: 899px)
/////////////////////////////////////////

._prevent_scroll {
    overflow:hidden;
}

.off-canvas-nav-container {
    position:fixed;
    top:$tab-bar__height + 38px;
    right:0;
    bottom:0;
    width:$off-canvas__width;
    background:rgba($color__primary, 0.95);
    pointer-events:none;
    z-index:100;
    transition:transform 0.8s cubic-bezier(0.5, 0, 0, 1);
    transform:translate3d(100%, 0, 0);
    overflow:hidden;

    // Menu is toggled
    &._is_active {
        z-index:1001;
        pointer-events:auto;
        transform:translate3d(0, 0, 0);
    }

    // viewport of the navigation, sub-menus get translated out
    // the viewport to be hidden
    .viewport {
        position:relative;
        width:100%;
        height:100%;
        pointer-events:auto;
        overflow-x:hidden;
        overflow-y:auto;
    }

    // general menu styling
    .mod_navigation ul {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        margin:0;
        width:100%;
        transition:transform 0.8s cubic-bezier(0.5, 0, 0, 1);
    }

    // sub Menu
    .submenu > ul {
        z-index:-1;
        pointer-events:none;

        // translate menu initially out of view
        transform:translate3d(100%, 0, 0);

        &._is_active {
            z-index:auto;
            pointer-events:auto;
        }
    }

    li {
        display:flex;
        align-items:stretch;
    }

    li > a,
    li > strong {
        flex-basis:70%;
    }

    // styles UI elements inside the navigation
    .ui {
        font-size:24px;
        color:$color__primary;
        background:rgba(255, 255, 255, 100);
        cursor:pointer;
        transition:all 0.4s ease-in-out;
        opacity:0;

        &:hover {
            color:$color__secondary;
        }

        // back button, gets injected by JS
        &.back {
            display:inline-block;
            padding:0 15px 1px 15px;
            border-top:0;

            &:before {
                content:'‹';
                color:$color__primary;
            }
        }

        // toggles the sub-menus, gets injected by JS
        &.toggle-tree {
            margin-left:auto;
            display:flex;
            align-items:center;
            padding:0 15px;

            &:after {
                content:'›';
                color:$color__primary;
            }
        }
    }

    // eye-candy link transition effects
    &._is_active .mod_navigation > ul > li > a,
    &._is_active .mod_navigation > ul > li > strong,
    &._is_active .mod_navigation > ul > li > span,
    ul._is_active > li > a,
    ul._is_active > li > span,
    ul._is_active > li > strong {
        opacity:1;
        transform:translate3d(0, 0, 0);
    }

    // show border, border is hidden by default
    &._is_active .mod_navigation > ul > li,
    ul._is_active > li {
        border-color:rgba(255, 255, 255, 100) !important;
    }

    // show top level ui elements
    &._is_active .level_1 > li > .ui {
        opacity:1 !important;
    }

    // show sub level ui elements
    ._is_active > .ui,
    ._is_active > li > .ui {
        opacity:1;
    }
}

// ====================================
// NAVIGATION SECTIONS
// ====================================

///
/// Contains the Menu
///
.off-canvas-nav-container .menu-section--nav {

    // list default styles
    ul {
        display:block;
        padding-left:0;
    }

    li {
        list-style:none;
        line-height:1.4;
        border-top:1px solid rgba(255, 255, 255, 0);
        transition:border 0.4s ease-in-out;

        &:last-child {
            border-bottom:1px solid rgba(255, 255, 255, 0);
        }
    }

    li > a,
    li > strong,
    li > span {
        display:block;
        padding:10px 0 10px 15px;
        color:#fff;
        opacity:0;
        font-size:12px;
        text-transform:uppercase;
        transform:translate3d(0, 8px, 0);
        transition:color 0.4s ease-in-out, transform 0.6s 0.4s ease-in-out;

        &:hover {
            color:$color__secondary;
        }
    }

    // active links
    .active {
        color:$color__secondary;
    }

    .level_1 li.first {
        border-top:0;
    }
}

////////////////////////////////////////////////////////////////////
//// FOOTER NAVIGATION
////////////////////////////////////////////////////////////////////

.footer-container .mod_customnav ul {
    margin:0;
    padding:0;

    li {
        @include _use(inline-block);
        margin-right:5px;
        list-style:none;

        a {
            color:$color__copy;
            font-weight:400;
            transition:all .3s ease;

            &:hover {
                color:$color__primary;
            }
        }
    }
}
